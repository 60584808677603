import { takeRightWhile } from "lodash"

/**
 * @description 创建菜单   末端节点
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elMenuItem (h, menu) {
  //console.log(menu,this.$i18n.locale);
  //根据所选语言类型，把菜单中的menuName都替换为对应的菜单标题
  if (this.$i18n.locale == "en"){
    //变为英语
    menu.title = menu.titleEnglish;
  }
  else if (this.$i18n.locale == "zh-chs"){
    //变为汉语
    menu.title = menu.titleChinese;
  }
  let icon = null
  let space = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
  if (menu.icon) icon = <i class={ `fa fa-${menu.icon}` }/>
  else if (menu.iconSvg) icon = <d2-icon-svg name={ menu.iconSvg }/>
  else icon = <i class="fa fa-file-o"/>
  if (menu.title.length==6){
    return <a href={ '#' + menu.path} style="padding-top:10px;marging-right:50px;">{ icon } { menu.title  || '未命名菜单' } </a>
  }
  else if (menu.title.length==5){
    return <a href={ '#' + menu.path} style="padding-top:10px;marging-right:50px;">{ icon } { menu.title  || '未命名菜单' } &nbsp;&nbsp;&nbsp;</a>
  }
  else if (menu.title.length == 4){
    return <a href={ '#' + menu.path} style="padding-top:10px;marging-right:50px;">{ icon } { menu.title  || '未命名菜单' } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
  }
  else if (menu.title.length == 3){
    return <a href={ '#' + menu.path} style="padding-top:10px;marging-right:50px;">{ icon } { menu.title  || '未命名菜单' } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
  }
  else if (menu.title.length == 2){
    return <a href={ '#' + menu.path} style="padding-top:10px;marging-right:50px;">{ icon } { menu.title  || '未命名菜单' } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
  }
  else{
    return <a href={ '#' + menu.path} style="padding-top:10px;marging-right:50px;">{ icon } { menu.title  || '未命名菜单' } &nbsp;</a>
  }
  
}

export function addlength(title){
  var rtn = '';
  /**/
  while(rtn.length < 7-title.length){
    rtn = rtn + '';
  }
  return rtn;
}

/**
 * @description 创建子菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elSubmenu (h, menu,bfirst) {
  let icon = null
  /*
  if (bfirst){
    console.log(menu.title);
  }*/
  //根据所选语言类型，把菜单中的menuName都替换为对应的菜单标题
  if (this.$i18n.locale == "en"){
    //变为英语
    menu.title = menu.titleEnglish;
  }
  else if (this.$i18n.locale == "zh-chs"){
    //变为汉语
    menu.title = menu.titleChinese;
  }
  if (menu.icon) icon = <i slot="title" class={ `fa fa-${menu.icon}` }/>
  else if (menu.iconSvg) icon = <d2-icon-svg slot="title" name={ menu.iconSvg }/>
  else icon = <i slot="title" class="fa fa-folder-o"/>
  if (bfirst == true){
    if (menu.title == '数据设备管理'){
      return <el-tab-pane >
        <span slot="label"><a href={ '#'} ref="menuclick" >{icon} {menu.title || '未命名菜单'}</a></span>
        <el-tabs>
          { menu.children.map(child => createMenu.call(this, h, child,false)) }
        </el-tabs>      
      </el-tab-pane>
    }
    else{
      return <el-tab-pane >
        <span slot="label"><a href={ '#'} >{icon} {menu.title || '未命名菜单'}</a></span>
        <el-tabs>
          { menu.children.map(child => createMenu.call(this, h, child,false)) }
        </el-tabs>      
      </el-tab-pane>
    }   
    
     

  }
  else{
    return <div style="height:100%;width:100%;text-align:left;"> {menu.children.map(child => createMenu.call(this, h, child,false))} </div>
  }
}

//只有第一级的首页菜单用到
export function elMenuItem1 (h, menu) {
  //根据所选语言类型，把菜单中的menuName都替换为对应的菜单标题
  if (this.$i18n.locale == "en"){
    //变为英语
    menu.title = menu.titleEnglish;
  }
  else if (this.$i18n.locale == "zh-chs"){
    //变为汉语
    menu.title = menu.titleChinese;
  }
  let icon = null
  if (menu.icon) icon = <i slot="title" class={ `fa fa-${menu.icon}` }/>
  else if (menu.iconSvg) icon = <d2-icon-svg slot="title" name={ menu.iconSvg }/>
  else icon = <i slot="title" class="fa fa-folder-o"/>
  return <el-tab-pane >
    <span slot="label"><a href={ '#' + menu.path} >{icon} {menu.title || '未命名菜单'}</a></span>
  </el-tab-pane>
}

/**
 * @description 在组件中调用此方法渲染菜单项目
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 * @first 是否第一层传入
 */
export function createMenu (h, menu,bfirst) {
  //根据所选语言类型，把菜单中的menuName都替换为对应的菜单标题
  if (this.$i18n.locale == "en"){
    //变为英语
    menu.title = menu.titleEnglish;
  }
  else if (this.$i18n.locale == "zh-chs"){
    //变为汉语
    menu.title = menu.titleChinese;
  }
  /*
  if (menu.children === undefined){
    if (menu.parentId == null){
      //一级节点 并且其下如果没有子节点，这个根节点不需要加载
      //return;
      return elMenuItem.call(this, h, menu)
    }
    else{
     return elMenuItem.call(this, h, menu)      
    }
  } 
  return elSubmenu.call(this, h, menu)
  */
  if (menu.children === undefined){
    if (menu.parentId == null){
      //一级节点 并且其下如果没有子节点，这个根节点不需要加载
      //return;
      return elMenuItem1.call(this, h, menu)
    }
    else{
     return elMenuItem.call(this, h, menu)      
    }
  }
  else{
    return elSubmenu.call(this, h, menu,bfirst)
  }
}
