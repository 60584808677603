<template>
  <el-tooltip effect="dark" :content="this.$t('mainpage.menuitem.lishishuju')" placement="bottom">
    <span>
    <d2-icon name="line-chart" class="d2-mr btn-text can-hover" @click.native="toggle" style="padding-left:10px;padding-right:10px;"/>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  computed: {

  },
  methods: {
    toggle(){
      //console.log('历史数据');
      //this.$router.push('/rest/datshow/list');
      this.$router.push('/rest/datshow/list2');
      //this.$router.push('/rest/datshow/dataolelazy1');//上面的历史数据显示标签未改，还是原来的历史数据而不是历史数据1，实际在菜单表里是历史数据1，用来测试，将来使用此个菜单的代码
      //this.$router.push('/devices/tree');
    }
  }
}
</script>
