<template>
  <el-dropdown placement="bottom" size="small" @command="onChangeLocale">
    
      <span class="btn-text" >
        <d2-icon name="language" style="font-size: 16px;padding-left:10px;padding-right:10px;"  />
      </span>
    
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="language in $languages"
        :key="language.value"
        :command="language.value">
        <d2-icon :name="$i18n.locale === language.value ? 'dot-circle-o' : 'circle-o'" class="d2-mr-5"/>
        {{ language.label }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import localeMixin from '@/locales/mixin.js'
export default {
  mixins: [
    localeMixin
  ]
}
</script>
