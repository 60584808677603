<template>
  <el-tooltip effect="dark" :content="'设备维护'" placement="bottom">
    <span>
    <d2-icon name="cog fa-lg" class="d2-mr btn-text can-hover" @click.native="toggle" style="padding-left:10px;padding-right:10px;"/>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  computed: {

  },
  methods: {
    toggle(){
      console.log('设备维护');
      this.$router.push('/devices/tree');
      //this.$router.push('/rest/datshow/list2');
      /*
      console.log('查询');
      this.$router.push('/rest/dataview/query');
      */
    }
  }
}
</script>
