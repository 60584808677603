<template>
  <!--<div class="d2-layout-header-aside-group" :style="styleLayoutMainGroup" :class="{grayMode: grayActive}"> style="overflow-x:auto;"-->
  <div class="d2-layout-header-aside-group" :style="styleLayoutMainGroup" >
    <!-- 半透明遮罩 -->
    <!-- <div class="d2-layout-header-aside-mask"></div> -->
    <!-- 主体内容 还是浏览器整个范围-->
    <div class="d2-layout-header-aside-content" flex="dir:top" style="border:0px solid rebeccapurple;overflow-x:hidden;">
      <!-- 顶栏 标题 名称 几个主按钮-->
      <div class="d2-theme-header" :style="{ opacity: this.searchActive ? 0.5 : 1 }" style="border:0px solid rebeccapurple" flex-box="0" flex >
        <!-- 去掉左上方点击返回首页连接，去掉下方to=后引号里的/index，原本是 to="/index"  -->
        <router-link
          v-if="true"
          to=""
          :class="{'logo-group': true, 'logo-transition': asideTransition}"
          :style="{width: asideCollapse ? asideWidthCollapse : asideWidth}"
          flex-box="0">
          <img v-if="roleid!=1"   style="padding-top:2px;padding-bottom:2px;margin-top: 3px;margin-bottom: 3px;padding: 3px;">
          <img v-else :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/logo/company1.png`" style="padding-top:2px;padding-bottom:2px;margin-top: 3px;margin-bottom: 3px;padding: 3px;" >
        </router-link>
        <div class="toggle-aside-btn" @click="handleToggleAside" flex-box="0" v-if="false">
          <d2-icon name="bars"/>
        </div>
        <div class=" fl t_title" v-if="true">
          {{ $t('mainpage.title') }}
        </div> 
        <d2-menu-header flex-box="1" class="head-menu" v-if="false" />
        <!-- 顶栏右侧 首页按钮 -->
        <div class="d2-header-right" flex-box="0">
          <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
          <!-- <d2-header-search @click="handleSearchClick"/>
          <d2-header-log/>
          <d2-menu-header/> -->
          <d2-home-page/>
          <d2-history-data />
          <d2-device-config v-if="false"/>
          <d2-menu-smalljs v-if="(roleid == 1)" />
          <d2-header-fullscreen/>
          <!-- <d2-header-theme/> -->
          <!-- <d2-header-size/> -->
          <d2-header-locales/>
          <!--<d2-header-color/>-->
          <d2-header-user/>
        </div>
      </div>
      <!-- 下面 主体 -->
      <div class="d2-theme-container" flex-box="1" flex style="border:0px solid green;">
        <!-- 主体 侧边栏 隐藏 -->
        <div
          flex-box="0"
          ref="aside"
          :class="{'d2-theme-container-aside': true, 'd2-theme-container-transition': asideTransition}"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            opacity: this.searchActive ? 0.5 : 1
          }"
          v-if="false">
          <d2-menu-side />
        </div>
        <!-- 主体 -->
        <div class="d2-theme-container-main" flex-box="1" flex >
          <!-- 搜索 -->
          <transition name="fade-scale">
            <div v-if="searchActive" class="d2-theme-container-main-layer" flex>
              <d2-panel-search ref="panelSearch" @close="searchPanelClose"/>
            </div>
          </transition>
          <!-- 内容 -->
          <transition name="fade-scale">
            <div v-if="!searchActive" class="d2-theme-container-main-layer" flex="dir:top" style="border:0px solid rebeccapurple">
              <!-- tab 页，去掉后，同时只显示一个页面
              <div class="d2-theme-container-main-header" flex-box="0">
                <d2-tabs/>
              </div>-->
              <!-- 页面 -->
              <div class="d2-theme-container-main-body" flex-box="1" style="border:0px solid rebeccapurple;">
                <transition :name="transitionActive ? 'fade-transverse' : ''">
                  <keep-alive :include="keepAlive">
                    <router-view style="border:0px solid rebeccapurple;" />
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import d2MenuSide from './components/menu-side'
import d2MenuHeader from './components/menu-header'
import d2Tabs from './components/tabs'
import d2HeaderFullscreen from './components/header-fullscreen'
import d2HomePage from './components/home-page'
import d2HistoryData from './components/history-data'
import d2DeviceConfig from './components/device-config'
import d2MenuSmalljs from './components/menu-smalljs'
import d2HeaderLocales from './components/header-locales'
import d2HeaderSearch from './components/header-search'
import d2HeaderSize from './components/header-size'
import d2HeaderTheme from './components/header-theme'
import d2HeaderUser from './components/header-user'
import d2HeaderLog from './components/header-log'
import d2HeaderColor from './components/header-color'
import { mapState, mapGetters, mapActions } from 'vuex'
import mixinSearch from './mixins/search'
import {
  request
} from '@/api/service'
export default {
  name: 'd2-layout-header-aside',
  mixins: [
    mixinSearch
  ],
  components: {
    d2MenuSide,
    d2MenuHeader,
    d2Tabs,
    d2HeaderFullscreen,
    d2HomePage,
    d2HistoryData,
    d2DeviceConfig,
    d2MenuSmalljs,
    d2HeaderLocales,
    d2HeaderSearch,
    d2HeaderSize,
    d2HeaderTheme,
    d2HeaderUser,
    d2HeaderLog,
    d2HeaderColor
  },
  data () {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '65px',
      roleid:0,
    }
  },
  computed: {
    ...mapState('d2admin', {
      keepAlive: state => state.page.keepAlive,
      grayActive: state => state.gray.active,
      transitionActive: state => state.transition.active,
      asideCollapse: state => state.menu.asideCollapse,
      asideTransition: state => state.menu.asideTransition
    }),
    ...mapGetters('d2admin', {
      themeActiveSetting: 'theme/activeSetting'
    }),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup () {
      return this.themeActiveSetting.backgroundImage
        ? { backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')` }
        : {}
    }
  },
  methods: {
    ...mapActions('d2admin/menu', [
      'asideCollapseToggle'
    ]),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside () {
      this.asideCollapseToggle()
    }
  },
  mounted() {
    //获取角色id
    /**/
    request({
      url: '/rest/user/getroleid',
      method: 'post'
    }).then(ret => {
      //console.log("获取角色id",ret.data);
      this.roleid = ret.data.roleId;
    });

  }
}
</script>

<style lang="scss" >
// 注册主题
@import '~@/assets/style/theme/register.scss';
@import '~@/assets/css/base.scss';
@import '~@/assets/css/index.scss';
// 菜单样式控制
.head-menu{
  padding-top: 42px;// 42px;//like 菜单下移
  margin-right: -10px;
}

.d2-theme-header{
  padding-left:0%;
}
//在img中使用 :class="logostyle" 方式调用，控制台输出找不到logostyle。把属性直接写到了style中
.logostyle{
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.d2-layout-header-aside-group .d2-layout-header-aside-content .d2-theme-header {
  height: 60px;//like 头部高度
  //min-width:1024px;
}

.el-button{
  background-color: transparent !important;
}

</style>

<style lang="scss">
/*修改浏览器默认滚动条样式*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {    
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {    
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {    
  border-radius: 10px;    
  background: rgba(0,0,0,0.1);    
  -webkit-box-shadow: inset 0 0 6px rgba(80, 55, 218, 0.9);
  box-shadow: inset 0 0 6px rgba(189, 189, 243, 0.9);
}
::-webkit-scrollbar-thumb:window-inactive {    
  background: rgba(0,0,0,0.3);
}
</style>

<style lang="scss">
  a {color:white;}
  a:link { text-decoration: none;color: rgb(255, 255, 255);float:right;padding-left:45px;}//like 菜单偏移
  a:active { text-decoration:none;color: rgb(255, 255, 255);}
  a:hover { text-decoration:none;color: #0954ec !important;}
  //a:visited { text-decoration: none;color: #0954ec !important;}
  /* tab菜单文字颜色 */
  .el-tabs__item{
    color: white;
  }
  /* tab标签靠右 */
  .el-tabs__nav{
    float:right;
  }
  /* 菜单级别间分割 */
  .el-tabs__nav-wrap::after {
    width: 100%;
    height: 1px !important;
    background-color: #0954ec !important;
  }
  /* 浮动菜单边框颜色*/
  .el-dropdown-menu {
    border-bottom: 1px solid #0954ec !important;
    border:0px;
  }
</style>