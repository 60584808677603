<template>
  <el-tooltip effect="dark" :content="active ? this.$t('mainpage.menuitem.tuichuquanping') : this.$t('mainpage.menuitem.quanping')" placement="bottom">
    <span>
      <d2-icon v-if="active" name="compress" style="font-size: 16px;padding-left:10px;padding-right:10px;" class="d2-mr btn-text can-hover" @click.native="toggle"  />
      <d2-icon v-else name="arrows-alt" style="font-size: 16px;padding-left:10px;padding-right:10px;" class="d2-mr btn-text can-hover"  @click.native="toggle"  />
    </span>
  </el-tooltip>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('d2admin/fullscreen', [
      'active'
    ])
  },
  methods: {
    ...mapActions('d2admin/fullscreen', [
      'toggle'
    ])
  }
}
</script>
