<template >
  <!-- 系统的整个范围 -->
  <div id="app" style="border:0px solid red;scroll:hidden;width:100%;height:100%;" >
    <router-view ></router-view>
    <router-view v-if="showPage == 1" name="m" ></router-view>
    <router-view v-if="showPage == 2" name="pc" ></router-view>
  </div>
</template>

<script>
import util from "@/libs/util";
export default {
  name: "app",
  watch: {
    "$i18n.locale": "i18nHandle",
  },
  data(){
    return{
      showPage: '',
    }
  },
  created() {
    this.i18nHandle(this.$i18n.locale);
    if (this._isMobile()) {
      this.showPage = 1
    } else {
      this.showPage = 2
    }
    //alert(this.showPage);
    console.log(this.showPage)
  },
  methods: {
    i18nHandle(val, oldVal) {
      util.cookies.set("lang", val);
      document.querySelector("html").setAttribute("lang", val);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  //在app.vue里面 执行this.getConfigJson();
  // mounted:function(){
  //   //this.getConfigJson();
  // }



};
</script>

<style src="./styles/theme.scss" lang="scss" />
 