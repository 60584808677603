//import exampleModule from './example/index'
//like 去掉，只留四行。
import './permission' // 加载permission

import { frameInRoutes } from '@/router/routes'
import { menuHeader, menuAside, supplementPath } from '@/menu/index'
import router from '@/router'
// 导出静态菜单和路由
//menuAside.push(...supplementPath(exampleModule.menus.aside))
//menuHeader.push(...supplementPath(exampleModule.menus.header), ...supplementPath(exampleModule.menus.aside))
//frameInRoutes[0].children.push(...exampleModule.routers.frameIn)

//const addRoutes = exampleModule.routers.frameIn
//console.log("getPermissions",addRoutes)
//router.addRoutes() is deprecated and has been removed in Vue Router 4. Use router.addRoute() instead
//router.addRoutes(addRoutes)
