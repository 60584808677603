import { throttle } from 'lodash'
import { mapState } from 'vuex'
import { createMenu } from '../libs/util.menu.small'

export default {
  name: 'd2-layout-header-aside-menu-small',
  render (h) {
    return  <el-dropdown placement="bottom" class="d2-mr can-hover" size="small" >
              <span class="btn-text" >
                <d2-icon name="bars" class="d2-mr btn-text can-hover" style="font-size: 16px;padding-left:10px;padding-right:10px;"/>
                </span>
              <el-dropdown-menu slot="dropdown" style="height:130px;width:800px;padding-right:0px;algin:center;">
                <el-tabs click="handleClick">
                  {this.header.map(menu => createMenu.call(this, h, menu,true)) }
                </el-tabs>
              </el-dropdown-menu>
            </el-dropdown>
  },
  computed: {
    ...mapState('d2admin/menu', [
      'header'
    ])
  },
  data () {
    return {

    }
  }
  ,methods: {
    handleClick(tab, event) {
      
      console.log(tab, event);
    }
  }
  ,mounted(){

  }
}
