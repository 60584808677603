export default {
  /**
   * 路由钩子
   * @param to
   * @param from
   * @param next
   * @returns {boolean} 是否已处理，返回true，则表示由本方法处理路由跳转
   *  async function (to, from, next) {
   *     awite xxx()
        return false
      }
   */
  beforeEach: undefined

}
