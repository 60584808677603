import { request } from '@/api/service'
export function getUserInfo () {
  return request({
    url: '/rest/user/getInfo',
    method: 'post'
  })
}
export function getPermissions (platformCode) {
  return request({
    url: '/rest/user/permissions',
    method: 'post'
  })
}
