import constantRoutes, { frameInRoutes } from '@/router/routes'
import StringUtils from '@/business/utils/util.string'
import layoutHeaderAside from '@/layout/header-aside'
import {
  menuHeader,
  menuAside,supplementPath
} from '@/menu'
const StatciMenuHeader = [...menuHeader]
/**
 * menuType 1=menu 2=btn 3=route
 * @param routers
 * @param list
 * @returns {[]}
 */
function formatRouter(parent, list) {
 
  if (parent == null) {
    parent = { children: [] }
  }
  
  list.forEach((item) => {
    let newRouter = parent
    //alert(newRouter)
    if (item.menuType !== 'button' && !StringUtils.isEmpty(item.menuComponent)) { // 如果是按钮 或者没有配置component，则不加入路由
      let menuComponent = null
      if (item.menuComponent === 'layoutHeaderAside') {
        menuComponent = layoutHeaderAside
      } else {
        menuComponent = () => import('@/business/modules' + item.menuComponent)
      }
      // if (item.menuComponent === 'layoutHeaderAsideCrm') {
      //   menuComponent = layoutHeaderAside
      // } else {
      //   menuComponent = () => import('@/business/modules' + item.menuComponent)
      // }
      const children = parent.children
      newRouter = {
        path: item.menuPath,
        name: item.id,
        hidden: false,
        // 动态路由支持懒加载
        component: menuComponent,
        meta: {
          title: item.menuName,
          titleChinese: item.menuNameChinese,
          titleEnglish: item.menuNameEnglish,
          titleJapanese: item.menuNameJapanese,
          auth: true,
          cache: false
        }
      }
      
      children.push(newRouter)
    }
   // if (item.children != null && item.children.length > 0) {
if (item.children != null && item.children.length > 0) {
      if (newRouter.children == null) {
        newRouter.children = []
  }
  //debugger
      formatRouter(newRouter, item.children)
    }
  })

  return parent.children
}

function formatMenu (menuTree) {
  if (menuTree == null) {
    menuTree = []
  }
  let menus = []
  
  menuTree.forEach((item) => {
    if (item.menuType !== 'menu') { // 只有菜单类型才加入
      return
    }
    let children
    if (item.children != null && item.children.length > 0) {
      children = formatMenu(item.children)
    }
    // let icon
    // if (item.icon != null && item.icon !== '') {
    //   icon = item.icon
    // }
    menus.push({ path: item.menuPath, title: item.menuName,titleChinese: item.menuNameChinese,titleEnglish: item.menuNameEnglish,titleJapanese: item.menuNameJapanese,icon: item.menuIcon, children: children,parentId:item.parentId })
  })
  if (menus.length === 0) {
    menus = undefined
  }
  return menus
}

function formatPermissions (menuTree, permissionList) {
  if (menuTree == null) {
    menuTree = []
  }
  menuTree.forEach((item) => {
    if (item.menuAuth != null && item.menuAuth !== '') { // 权限为空
      permissionList.push(item.menuAuth)
    }
    if (item.children != null && item.children.length > 0) {
      formatPermissions(item.children, permissionList)
    }
  })
  return permissionList
}

// export function buildRoutes (routes) {
//   return [{
//     path: '/',
//     redirect: { name: 'index' },
//     component: layoutHeaderAside,
//     children: routes
//   }]
// }

const state = {
  routes: [],
  addRoutes: [],
  permissions: [],
  inited: false
}

const mutations = {
  SET_ROUTES: (state, { accessedRoutes: routes, permissions }) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
    state.inited = true
    state.permissions = permissions
  },
  clear: () => {
    state.addRoutes = []
    state.routes = []
    state.inited = false
    state.permissions = []
  }
}

const actions = {
  generateRoutes({ commit }, { menuTree }) {
    return new Promise(resolve => {
      const accessedRoutes = formatRouter(null, menuTree)
      const permissions = formatPermissions(menuTree, [])
      //console.log('permission Routers', accessedRoutes)
      commit('SET_ROUTES', { accessedRoutes, permissions })

      const menus = supplementPath(formatMenu(menuTree))
      menuHeader.splice(0, menuHeader.length)
      menuHeader.push(...StatciMenuHeader)
      menuHeader.push(...menus)
      //console.log('accessRouter:', accessedRoutes)

      // 处理路由 得到每一级的路由设置
      commit('d2admin/page/init', frameInRoutes.concat(accessedRoutes), { root: true })
      // 设置顶栏菜单
      commit('d2admin/menu/headerSet', menuHeader, { root: true })
      // 设置侧边栏菜单
      //commit('d2admin/menu/asideSet', menuAside, {root: true }) //like
      // 初始化菜单搜索功能
      //commit('d2admin/search/init', menuHeader, { root: true })

      resolve(accessedRoutes)
    })
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const getters = {
  inited (state) {
    return state.inited
  },
  permissions (state) {
    return state.permissions
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
