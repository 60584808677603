// Vue
import Vue from "vue";
import i18n from "./i18n";
import App from "./App";
// 核心插件
import d2Admin from "@/plugin/d2admin";
// store
import store from "@/store/index";
// 菜单和路由设置
import router from "./router";
import {
  frameInRoutes
} from "@/router/routes";
import {
  menuHeader
} from "@/menu";
import "@/business";
import BootstrapVue from "bootstrap-vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VueTouch from "vue-touch";
import Trend from "vuetrend";
import Toasted from "vue-toasted";
import VueApexCharts from "vue-apexcharts";
import {
  d2CrudPlus
} from "d2-crud-plus";
// 如果需要slot功能，要将d2-crud替换为d2-crud-x【其他与d2-crud一致】
import d2CrudX from "d2-crud-x";
import d2Crud from "@d2-projects/d2-crud";
import {
  request
} from "@/api/service";
import Widget from "./components/Widget/Widget";
Vue.use(d2CrudX, {
  name: "d2-crud-x"
}); //注册名称为d2-crud-x ，不传name则使用d2-crud作为标签名称
Vue.use(VueApexCharts);

Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.component('Widget', Widget);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
  },
});
Vue.component('apexchart', VueApexCharts);
Vue.use(Toasted, {
  duration: 10000
});
Vue.config.productionTip = false;
//  自定义字段类型示例
d2CrudPlus.util.columnResolve.addTypes({
  time2: {
    form: {
      component: {
        name: "el-date-picker",
        props: {
          type: "datetime",
          format: "yyyy-MM-dd HH:mm:ss",
          valueFormat: "yyyy-MM-dd HH:mm:ss"
        }
      }
    }, // 编辑时支持日期时间
    search: {
      component: {
        props: {
          type: "date"
        }
      }
    }, // 搜索时只支持日期
    formatter(row, column, value, index) {
      return value;
    },
    _handle(column) {
      //  此方法主要将column中某些依赖的用户配置的属性放到默认配置中，比如数据字典的配置
      if (column.dict != null) {
        this.form.component.props.dict = column.dict;
        this.component.props.dict = column.dict;
      }
    }
  }
});

//添加自定义字段类型
d2CrudPlus.util.columnResolve.addTypes({
  diytime: {
    form: {
      component: {
        name: "el-date-picker",
        type: "datetime",
        props: {
          format: "yyyy-MM-dd HH:mm:ss",
          valueFormat: "yyyy-MM-dd HH:mm:ss"
        }
      }
    },
    component: {
      name: "date-format",
      props: {
        format: "yyyy-MM-dd HH:mm:ss",
        valueFormat: "yyyy-MM-dd HH:mm:ss"
      }
    },
    _handle(column) {} // 此方法将在运行时执行。在自定义配置与默认配置合并之后执行
  }
});
Vue.use(d2CrudPlus, {starTip:false,//like
  //获取数据字典的请求方法
  //可在dict中配置getData方法覆盖此全局方法
  getRemoteDictFunc(url, dict) {
    return request({
      url: url,
      method: "get"
    }).then(ret => {
      return ret.data; //返回字典数组
    });
  },

  commonOption() {
    //d2-crud option 全局设置，可以不用配置
    return {
      format: {
        page: {
          // page接口返回的数据结构配置，
          request: {
            // 请求参数
            current: "current", //当前
            size: "size"
          },
          response: {
            //返回结果
            current: "current", // 当前页码 ret.data.current
            size: "size", // 每页条数，ret.data.size,
            //size: (data) => { return data.size }, //你也可以配置一个方法，自定义返回
            total: "total", // 总记录数 ret.data.total
            records: "records" // 列表数组 ret.data.records
          },
          options: {
            size: "mini"
          } //全局配置表格大小
        }
      },
      formOptions: {
        defaultSpan: 12 // 默认的表单 span
      },
      // options: {
      //   height: '100%' // 表格高度100%, 使用toolbar必须设置
      // },
      pageOptions: {
        compact: false // 是否紧凑型页面
      },
      viewOptions: {
        disabled: false // 开启查看按钮
      }
    };
  }
});
// 核心插件
Vue.use(d2Admin);

Vue.use(d2Crud);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit("d2admin/page/init", frameInRoutes);//like
    // 设置顶栏菜单
    this.$store.commit("d2admin/menu/headerSet", menuHeader);//like
    // // 设置侧边栏菜单
    //this.$store.commit("d2admin/menu/asideSet", menuHeader[2].children);//like
    // 初始化菜单搜索功能
    //this.$store.commit("d2admin/search/init", menuHeader);//like
  },
  mounted() {
    // 展示系统信息
    this.$store.commit("d2admin/releases/versionShow");
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch("d2admin/account/load");
    // 获取并记录用户 UA
    this.$store.commit("d2admin/ua/get");
    // 初始化全屏监听
    this.$store.dispatch("d2admin/fullscreen/listen");
  },
  watch: {
    // 检测路由变化切换侧边栏内容
    "$route.matched": {
      handler(matched) {
        if (matched.length > 0) {
          //console.log("_side:", menuHeader, matched);
          const _side = menuHeader.filter(
            menu => menu.path === matched[0].path
          );
          if (_side.length > 0) {
            this.$store.commit("d2admin/menu/asideSet", _side[0].children);
          }
        }
      },
      immediate: true
    }
  }
}).$mount("#app");
//在main.js中定义一个全局函数
Vue.prototype.getConfigJson=function(){
  this.$http.get("serverconfig.json").then((result)=>{
    //用一个全局字段保存ApiUrl 也可以用sessionStorage存储
    console.log("serverconfig.json:");
    console.log(result);
    Vue.prototype.ApiUrl=result.body.ApiUrl;
  }).catch((error)=>{console.log(error)});
}