<template>
  <el-tooltip effect="dark" :content="this.$t('mainpage.menuitem.shouye')" placement="bottom">
    <span>
    <d2-icon name="home fa-lg" class="d2-mr btn-text can-hover" @click.native="toggle" style="padding-left:10px;padding-right:10px;"/>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  computed: {

  },
  methods: {
    toggle(){
      //console.log('首页');
      this.$router.push('/');
    }
  }
}
</script>
