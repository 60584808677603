export default {
  methods: {
    onChangeLocale (command) {
      this.$i18n.locale = command
      let message = `${this.$t('mainpage.yuyan.dangqianyuyan')}：${this.$t('_name')} [ ${this.$i18n.locale} ]`
      if (process.env.VUE_APP_BUILD_MODE === 'PREVIEW') {
        message = [
          `${this.$t('mainpage.yuyan.dangqianyuyan')}：${this.$t('_name')} [ ${this.$i18n.locale} ]`,
          '仅提供切换功能，没有配置具体的语言数据 ',
          '文档参考：<a class="el-link el-link--primary is-underline" target="_blank" href="https://d2.pub/zh/doc/d2-admin/locales">《国际化 | D2Admin》</a>'
        ].join('<br/>')
      }
      //提示语言变更
      this.$notify({
        duration:1000,
        title: this.$t('mainpage.yuyan.yuyanbiangeng'),
        dangerouslyUseHTMLString: true,
        message
      })
    }
  }
}
