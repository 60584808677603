import {
  Message,
  MessageBox
} from 'element-ui'
import util from '@/libs/util.js'
import router from '@/router'
import api from '@/api'
import ElementUI from 'element-ui';
export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    async login({
      dispatch
    }, {
      accountName = '',
      password = '',
      code = ''
    } = {}) {
      const res = await api.SYS_USER_LOGIN({
        accountName,
        password,
        code
      })
      // 设置 cookie 一定要存 uuid 和 token 两个 cookie
      // 整个系统依赖这两个数据进行校验和存储
      // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
      // token 代表用户当前登录状态 建议在网络请求中携带 token
      // 如有必要 token 需要定时更新，默认保存一天
      if (res.data!=null) {
        util.cookies.set('uuid', res.data.loginToken)
        util.cookies.set('token', res.data.loginToken)
      } else {
        //Message 消息提示
        ElementUI.Notification({
          message: res.msg,
          type: 'error'
        });
      }
      // HTTPrequest拦截

      // if (util.cookies.get('token')) {
      //   config.headers['Authorization'] = 'Bearer ' + util.cookies.get('token') // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
      // } 

      await api.SYS_USER_INFO({})

      //设置权限
      
      await api.SYS_PERMISSIONS({})
      
      // 设置 vuex 用户信息
      await dispatch('d2admin/user/set', {
        name: accountName
      }, {
        root: true
      })
      // 用户登录后从持久化数据加载一系列的设置
      await dispatch('load')
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout({
      commit,
      dispatch
    }, {
      confirm = false
    } = {}) {
      //console.log('logout1')
      /**
       * @description 注销
       */
      async function logout() {
        //console.log('logout3')
        // 删除cookie
        util.cookies.remove('token')
        util.cookies.remove('uuid')
        // 清空 vuex 用户信息
        await dispatch('d2admin/user/set', {}, {
          root: true
        })
        // 跳转路由
        router.push({
          name: 'login'
        })
        
        //$router.replace({ path: '/' })
      }
      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, {
          root: true
        })
        MessageBox.confirm('确定要注销当前用户吗', '注销用户', {
          type: 'warning'
        })
        .then(() => {
          
          //location.reload(false)//like 强制刷新放这里最合适。
          commit('d2admin/gray/set', false, {
            root: true
          })
          //console.log('logout2')
          logout()
          //console.log('logout4')
          /*
          //增加下面两部分，否则没有强制刷新浏览器，切换用户时，菜单系统并未根据权限加载。
          //1、设置路由
          router.push({
            name: 'login'
          })
          //2、强制刷新
          location.reload(false)//like 退出后强制刷新页面，否则菜单不会根据权限控制显示，只有强制刷新才会根据权限加载菜单，所以添加此强制刷新。
          */
        })
        .catch(() => {
          commit('d2admin/gray/set', false, {
            root: true
          })
          Message({
            message: '取消注销操作'
          })
        })
      } else {
        logout()
      }
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    async load({
      dispatch
    }) {
      // 加载用户名
      await dispatch('d2admin/user/load', null, {
        root: true
      })
      // 加载主题
      await dispatch('d2admin/theme/load', null, {
        root: true
      })
      // 加载页面过渡效果设置
      await dispatch('d2admin/transition/load', null, {
        root: true
      })
      // 持久化数据加载上次退出时的多页列表
      await dispatch('d2admin/page/openedLoad', null, {
        root: true
      })
      // 持久化数据加载侧边栏配置
      await dispatch('d2admin/menu/asideLoad', null, {
        root: true
      })
      // 持久化数据加载全局尺寸
      await dispatch('d2admin/size/load', null, {
        root: true
      })
      // 持久化数据加载颜色设置
      await dispatch('d2admin/color/load', null, {
        root: true
      })
    }
  }
}
