export function getcolor(){
    var color = [];
        color[0] = '#00a8e1';
        color[1] = '#99cc00';
        color[2] = '#e30039';
        color[3] = '#fcd300';
        color[4] = '#800080';
        color[5] = '#00994e';
        color[6] = '#ff6600';
        color[7] = '#808000';
        color[8] = '#db00c2';
        color[9] = '#008080';
        color[10] = '#0000ff';
        color[11] = '#c8cc00';
        color[12] = '#660000';
        color[13] = '#006600';
        color[14] = '#CCFFCC';
        color[15] = '#CCCC99';
        color[16] = '#FF9900';
        color[17] = '#0099FF';
        color[18] = '#99CCFF';
        color[19] = '#0099CC';
        color[20] = '#FFFFCC';
        return color
  }