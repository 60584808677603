export default ({
  service,
  request
}) => ({
  /**
   * @description 登录
   * @param {Object} data 登录携带的信息
   */
  SYS_USER_LOGIN (data = {}) {
    // 模拟数据
    // 接口请求
    //TODO
    return request({
      url: '/login',
      method: 'post',
      data
    })
  },
  SYS_PERMISSIONS (data = {}) {
    return request({
      url: '/rest/user/permissions',
      method: 'post'
    })
  },

  SYS_USER_INFO (data = {}) {
    // 接口请求
    return request({
      url: '/rest/user/getInfo',
      method: 'post',
      data
    })
  }
  

})
