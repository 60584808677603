<template>
  <el-dropdown size="small" class="d2-mr" style="padding-left:10px;padding-right:10px;">
    <span class="btn-text" >{{info.name ? this.$t('mainpage.menuitem.user.nihao') + ': ' + info.name : this.$t('mainpage.menuitem.user.weidenglu')}}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="modiPassword" v-if="true">
        <d2-icon name="lock" class="d2-mr-5"/>
        {{this.$t('mainpage.menuitem.user.xiugaimima')}}
      </el-dropdown-item>
      <el-dropdown-item @click.native="logOff">
        <d2-icon name="power-off" class="d2-mr-5"/>
        {{this.$t('mainpage.menuitem.user.zhuxiao')}}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ])
  },
  methods: {
    ...mapActions('d2admin/account', [
      'logout'
    ]),
    /**
     * @description 登出
     */
    logOff () {
      this.logout({
        confirm: true
      })
    }
    ,modiPassword(){
      this.$router.push('/rest/user/passlist');
    }
  }
}
</script>
